.current{
    color: green;
}
.link{
    color: gray;
}
.navbar-brand{
    margin-right: 0rem;
}
.bg-light{
    background-color:white !important;
}
.homebrand{
    position: relative;
    z-index: 2000;
    top: 0%;

}
.brandbar{
    position: absolute;
    top: 3%;
    left: 0%;
}
.logo{
    max-width: 25vw;
    padding: 1vw;
}
.brand{
    height: 90px;
    overflow: visible;
}
/*
.spacer{
    //margin-bottom: 10vh;
    margin-bottom: $spacer * 5.5;
}
*/
.logot{
    max-width: 120px;
}
.homebrandworks{
    position: relative;
    z-index: 2000;
    top: 0%;
    left: 42vw;
}
.logoworks{
    max-width: 175px;
}
.bg-lightworks{
    background-color:#ffeec8 !important;
}
.searchbar{
    height: 20px;
    background-color: #faf2c7;
}
.searchicon{
    height: 20px;
    width: 20px;
    text-align: center;
    color: black;
}

//For highlight areas
.redhighlight{
    background-color:#de7254;
}
.bluehighlight{
    background-color: #494d6e;
}
.greenhighlight{
    background-color: #81b29a;
}
.mainhighlight{
    background-color: #faf2c7;
}
.lightmain{
    background-color: #faf5dd;
}
.social{
    max-height: 30px;
    min-height: 15px;
}
.socialcont{
    width: 50px;
}
.textshadow{
    text-shadow: #2A3D4E 1px 1px,#2A3D4E -0px 0px,#2A3D4E -1px 1px
}
.sitecolor{
    background-color: #faf2c7;
}
.icon{
    opacity: 0.5;
    
}